
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul li {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 0.1em;
    border-radius: 5px;
    height: 52px;
}
ul li.dropArea {
    color: white !important;
    background: white !important;
    position: relative;
}
ul li.dropArea::before {
    content: "Drop Here";
    color: rgb(var(--orngClRgb),.8);
    font-size: 0.8em;
    text-transform: uppercase;
    width: 99.8%;
    height: 100%;
    border: 2px dashed rgb(var(--orngClRgb),.8);
    border-radius: 3px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
ul li.dropArea span {
    display: none;
}
ul li.dropArea textarea {
    margin-left: 1em;
}

ul li textarea {
    transition: margin-left .2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
@keyframes fadeIn {
    0% {
        opacity: 0.2;
   }
    100% {
        opacity: 1;
   }
}
